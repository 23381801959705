import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { createRoot } from 'react-dom/client';
import './RightContainer.css';
import CreateFolderForm from '../CommonComponent/AccessRepo/CreateFolderForm';
import UploadFiles from '../CommonComponent/AccessRepo/UploadFiles';
import { DownloadContent, singleFileDownload, getBuildsForPipeline, getArtifactFromBuild, downloadUrlData, downloadDataForSingleFileFolder } from '../CommonComponent/AccessRepo/DownloadContent';
import { BuildDetails, getPipelineRunUsingVariable, approvalForPrerelease, fetchDataRootLevel, finalApprovalRequest, sendCustomEmail, downloadUrlDataAndFetchFile } from '../CommonComponent/AccessRepo/BuildDetails'
import { accessToken } from '../../Hook/userRoles';
import AzureBlobUploader from '../CommonComponent/AccessRepo/AzureBlobUploader.js'
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { listBlobsInContainer, getBlobContent, downloadBlob, getFolderBlobMetadata, updateBlobMetadata, copyBlobsBetweenFolders, getBlobMetadata } from '../CommonComponent/AccessRepo/AzureBlobUploader.js'
import ErrorPopup from '../CommonComponent/ModelPopup/ErrorPopup.jsx';
import ApproveBuildPopup from '../CommonComponent/ModelPopup/ApproveButtonPopup.js'
import FileViewerPopup from '../CommonComponent/ModelPopup/FileViewerPopup.js'

import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleOutlinedIcon from '@mui/icons-material/ViewModuleOutlined';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import JSZip from 'jszip';
window.refreshCurrentPage = '';
let interval;
const RightContainer = ({ branchNameS, rootPath, userRole, triggerFetchRecived, handleFileClick, dataFrobranches, memoizedActiveFileContent, minimizeBranch, setShowCreateFolderFormS }) => {
  const { instance, accounts } = useMsal();
  const [folderPath, setFolderPath] = useState(rootPath);
  const [nestedData, setNestedData] = useState(null);
  const [showCreateFolderForm, setShowCreateFolderForm] = useState(false);
  const [showUploadFiles, setshowUploadFiles] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [view, setView] = useState(window.view);
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);
  const [showApproveButtonPup, setshowApproveButtonPup] = useState(false);
  const [showFileContent, setshowFileContent] = useState(false);
  const [fileContentView, setfileContentView] = useState();
  const [message, setMessage] = useState('');
  const [branchName, setBranchName] = useState(branchNameS);
  const [approvalID, setApprovalID] = useState('');
  let errorResult;
  const handelComponentErrors = (result) => {
    if (result) {
      if (result.error) {
        setMessage('There was a problem in operation, Please refresh the page and try again');
        setIsErrorPopupOpen(true);
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      }
    }
  }
  const closeErrorPopup = (newPlatform = false) => {
    setIsErrorPopupOpen(false);
    setShowCreateFolderForm(false);
    setshowUploadFiles(false);
    setshowApproveButtonPup(false);
    if (newPlatform && (folderPath == '' || folderPath == null || folderPath == undefined)) {
      fetchData(branchNameS, '');
    } else {
      handleDoubleClick(window.refreshCurrentPage.index, window.refreshCurrentPage.BuildId, window.refreshCurrentPage.srcPath, null, window.refreshCurrentPage.lastChangedDate, branchName);
    }
    hideLoading();
  };

  useEffect(() => {
    // Reset folderPath to null when branchName changes
    setFolderPath('');
    setBranchName(branchNameS);

    window.folderPath = '';
    window.branchUpdate = branchNameS;
    if (!folderPath.includes("Validation") && (!folderPath.includes("BusinessRelease") || folderPath.includes("BusinessReleaseDocs")) && !folderPath.includes("Prerelease")) {
      fetchData(branchNameS, '');
    } else {
      if (nestedData === null) {
        setNestedData("");
      } else {
        setNestedData(null);
      }
    }


  }, [branchNameS, triggerFetchRecived]); // Only re-run the effect when branchName changes

  // Function to show loading animation
  function showLoading() {
    var loadingContainer = document.getElementById('loadingContainer');
    if (loadingContainer && loadingContainer.style) {
      loadingContainer.style.display = 'flex';
    }
  }

  // Function to hide loading animation
  function hideLoading() {
    var loadingContainer = document.getElementById('loadingContainer');
    if (loadingContainer && loadingContainer.style) {
      loadingContainer.style.display = 'none';
    }
  }


  const handleThumbnailView = (event) => {
    event.preventDefault();
    window.view = "Thumbnail";
    setView("Thumbnail");
  }
  const handleListView = (event) => {
    event.preventDefault();
    window.view = "List";
    setView("List");
  }
  const handleDoubleClick = async (index, BuildId, srcPath, event, lastChangedDate, updatedBranchname) => {

    document.getElementById('Rejected')?.classList?.add('d-none');
    document.getElementById('Approved')?.classList?.add('d-none');
    document.getElementById('Inprogress')?.classList?.add('d-none');
    setFolderPath(index);
    window.folderPath = index;
    window.branchUpdate = updatedBranchname;
    setBranchName(updatedBranchname);

    if (event) {
      event.stopPropagation();
    }

    window.refreshCurrentPage = { index: index, BuildId: BuildId, srcPath: srcPath, lastChangedDate: lastChangedDate };
    // document.getElementById('AppContainer')?.classList?.add('excluxivityEnable');
    if (index) {
      if (index.includes('Prerelease') && !index.includes('Validation') && !index.includes('BusinessReleaseDocs')) {

        showLoading();
        const dataForValidationFolder = await getBuildsForPipeline(process.env.REACT_APP_PreReleasePipeline, instance, accounts, index.includes('/Archive') ? index.replaceAll("/Archive", '') : index, branchName);
        if (dataForValidationFolder.showMessage === true) {
          setIsErrorPopupOpen(true); // Toggle the state
          setMessage(dataForValidationFolder.message);
          hideLoading();
          document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
          return;
        }
        if (srcPath) {
          window.srcFolder = srcPath;
        }

        if (index.split('/').pop().includes('Prerelease') || index.split('/').pop().includes('Archive')) {
          // Step 1: Extract and sort data to get the latest builds
          let extractedData = dataForValidationFolder.reduce((acc, data) => {
            // Check if entry already exists for the destinationFolder
            const existingEntry = acc.find(entry => entry.path === data.parameters.destinationFolder);

            // If no entry exists or the new buildNumber is newer, replace or add the entry
            if (!existingEntry || isNewerBuildNumber(data.buildNumber, existingEntry.buildNumber)) {
              acc = acc.filter(entry => entry.path !== data.parameters.destinationFolder); // Remove existing entry if any
              acc.push({
                BuildId: data.id,
                isFolder: true,
                isOpen: false,
                path: data.parameters.destinationFolder,
                buildNumber: data.buildNumber,
                srcPath: data.parameters.srcFolder,
                lastChangedDate: data.lastChangedDate
              });
            }

            return acc;
          }, []);

          // Sort extractedData by buildNumber descending to get the latest versions first
          extractedData.sort((a, b) => compareBuildNumbersDescending(a.buildNumber, b.buildNumber));

          // Step 2: Determine the immediate previous build and handle cases with gaps for each entry in extractedData
          let previousVersionsData = extractedData.map(entry => {
            const currentVersion = entry.buildNumber;
            const immediatePreviousBuild = findImmediatePreviousBuildNumber(currentVersion);

            if (immediatePreviousBuild) {
              // If immediate previous build found, return it
              return {
                BuildId: immediatePreviousBuild.id,
                isFolder: true,
                isOpen: false,
                path: entry.path,
                buildNumber: immediatePreviousBuild.buildNumber,
                srcPath: immediatePreviousBuild.parameters.srcFolder,
                lastChangedDate: immediatePreviousBuild.lastChangedDate
              };
            } else {
              // If no immediate previous build, find the closest lower build
              const allLowerVersions = dataForValidationFolder
                .filter(item => compareBuildNumbersDescending(item.buildNumber, currentVersion) > 0)
                .sort((a, b) => compareBuildNumbersDescending(a.buildNumber, b.buildNumber));

              if (allLowerVersions.length > 0) {
                // Return the highest of the lower versions
                return {
                  BuildId: allLowerVersions[0].id,
                  isFolder: true,
                  isOpen: false,
                  path: entry.path,
                  buildNumber: allLowerVersions[0].buildNumber,
                  srcPath: allLowerVersions[0].parameters.srcFolder,
                  lastChangedDate: allLowerVersions[0].lastChangedDate
                };
              }
            }

            // If no previous build is found, return null or an empty object
            return null;
          }).filter(data => data !== null); // Remove any null entries

          // Helper functions

          function isNewerBuildNumber(newBuildNumber, currentBuildNumber) {
            return getVersionNumber(newBuildNumber) > getVersionNumber(currentBuildNumber);
          }

          function compareBuildNumbersDescending(buildNumberA, buildNumberB) {
            return getVersionNumber(buildNumberB) - getVersionNumber(buildNumberA);
          }

          function getVersionNumber(buildNumber) {
            return parseInt(buildNumber.split('.').pop());
          }

          function findImmediatePreviousBuildNumber(currentBuildNumber) {
            const currentVersionNumber = getVersionNumber(currentBuildNumber);
            const possiblePreviousNumber = currentVersionNumber - 1;

            // Look for the immediate previous build number
            const previousBuild = dataForValidationFolder.find(item => {
              return getVersionNumber(item.buildNumber) === possiblePreviousNumber &&
                item.buildNumber.startsWith(currentBuildNumber.split('.').slice(0, -1).join('.'));
            });

            return previousBuild;
          }

          if (index.split('/').pop() === 'Archive') {
            function updatePaths(data) {
              return data.map(item => {
                let pathParts = item.path.split('/');

                // Find the index of 'ValidationRelease' in pathParts
                let validationIndex = pathParts.findIndex(part => part.startsWith('ValidationRelease'));

                if (validationIndex !== -1) {
                  // 'ValidationRelease' is found, insert 'Archive' after it
                  let versionPart = pathParts[validationIndex]; // Get the version part
                  let versionIndex = versionPart.indexOf('ValidationRelease') + 'ValidationRelease'.length; // Get the index where version starts

                  // Extract version number
                  let versionNumber = versionPart.substring(versionIndex);

                  // Insert 'Archive' after the version number
                  pathParts.splice(validationIndex + 1, 0, 'Archive');
                } else {
                  // Find the index of 'Prerelease' in pathParts
                  let prereleaseIndex = pathParts.findIndex(part => part === 'Prerelease');

                  if (prereleaseIndex !== -1) {
                    // 'Prerelease' is found, insert 'Archive' after it
                    pathParts.splice(prereleaseIndex + 1, 0, 'Archive');
                  } else {
                    // Handle cases where neither 'ValidationRelease' nor 'Prerelease' is found
                    console.error(`Unable to update path for item: ${JSON.stringify(item)}`);
                    return item;
                  }
                }

                // Join the updated path parts back into a string
                item.path = pathParts.join('/') + '.zip';
                item.isFolder = false;
                return item;
              });
            }


            // Updating paths in the array
            previousVersionsData = updatePaths(previousVersionsData);
            if (index.includes('Archive')) {
              setNestedData(previousVersionsData);
            }
            window.buildIDArray = previousVersionsData.map(items => items.BuildId).filter(buildId => buildId !== undefined && buildId !== '');
            hideLoading();
          } else {
            extractedData.push({
              BuildId: '',
              isFolder: true,
              isOpen: false,
              path: `${index}/Archive`,
              buildNumber: '',
              srcPath: '',
              lastChangedDate: ''
            });
            window.buildIDArray = extractedData.map(items => items.BuildId).filter(buildId => buildId !== undefined && buildId !== '');
            if (index.includes('Archive')) {
              setNestedData(extractedData);
            }
            hideLoading();
          }
        } else if (BuildId) {
          window.folderName = index;
          const result = await approvalForPrerelease(dataForValidationFolder, BuildId, instance, accounts);
          if (result.showMessage === true) {
            setIsErrorPopupOpen(true); // Toggle the state
            setMessage(result.message);
            hideLoading();
            document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
            return;
          }
          const dataForthe = index.split('/').pop();
          const uniqueFilteredData = result
            .map(item => {
              if (item.relativePath.includes(dataForthe)) {
                const dataSplit = item.relativePath.split(dataForthe);
                const dataSlitIndex = index.split(dataForthe);
                const dataSplitS = dataSplit[1].split('/');
                const isFolder = dataSplitS[1] && !dataSplitS[2] ? false : true;
                return {
                  BuildId: BuildId,
                  isFolder: isFolder,
                  isOpen: false,
                  path: index + "/" + dataSplitS[1],
                  buildNumber: '',
                  srcPath: '',
                  fileContent: item.fileContent,
                  lastChangedDate: lastChangedDate
                };
              }
              // Ensure to return null or undefined for items that don't match the condition
              return null; // or undefined
            })
            .filter((item, index, array) => {
              // Check if item is defined and has a path property
              if (item) {
                // Use Array.findIndex to check for first occurrence of item.path
                return index === array.findIndex(obj => {
                  if (obj) {
                    return obj.path === item.path;
                  }
                });
              }
              // If item is undefined or doesn't have a path property, filter it out
              return false;
            });
          if (index.includes('Archive')) {
            setNestedData(uniqueFilteredData);
          }
          hideLoading();
        }

      } else if (index.includes('BusinessRelease') && !index.includes('Validation') && !index.includes('BusinessReleaseDocs')) {

        showLoading();
        const dataForValidationFolder = await getBuildsForPipeline(process.env.REACT_APP_ReleasePipeline, instance, accounts, index.includes('/Archive') ? index.replaceAll("/Archive", '') : index, branchName);
        if (dataForValidationFolder.showMessage === true) {
          setIsErrorPopupOpen(true); // Toggle the state
          setMessage(dataForValidationFolder.message);
          hideLoading();
          document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
          return;
        }
        if (srcPath) {
          window.srcFolder = srcPath;
        }
        if (index.split('/').pop().includes('BusinessRelease') || index.split('/').pop().includes('Archive')) {
          // Step 1: Extract and sort data to get the latest builds
          let extractedData = dataForValidationFolder.reduce((acc, data) => {
            // Check if entry already exists for the destinationFolder
            const existingEntry = acc.find(entry => entry.path === data.parameters.destinationFolder);

            // If no entry exists or the new buildNumber is newer, replace or add the entry
            if (!existingEntry || isNewerBuildNumber(data.buildNumber, existingEntry.buildNumber)) {
              acc = acc.filter(entry => entry.path !== data.parameters.destinationFolder); // Remove existing entry if any
              acc.push({
                BuildId: data.id,
                isFolder: true,
                isOpen: false,
                path: data.parameters.destinationFolder,
                buildNumber: data.buildNumber,
                srcPath: data.parameters.srcFolder,
                lastChangedDate: data.lastChangedDate
              });
            }

            return acc;
          }, []);

          // Sort extractedData by buildNumber descending to get the latest versions first
          extractedData.sort((a, b) => compareBuildNumbersDescending(a.buildNumber, b.buildNumber));

          // Step 2: Determine the immediate previous build and handle cases with gaps for each entry in extractedData
          let previousVersionsData = extractedData.map(entry => {
            const currentVersion = entry.buildNumber;
            const immediatePreviousBuild = findImmediatePreviousBuildNumber(currentVersion);

            if (immediatePreviousBuild) {
              // If immediate previous build found, return it
              return {
                BuildId: immediatePreviousBuild.id,
                isFolder: true,
                isOpen: false,
                path: entry.path,
                buildNumber: immediatePreviousBuild.buildNumber,
                srcPath: immediatePreviousBuild.parameters.srcFolder,
                lastChangedDate: immediatePreviousBuild.lastChangedDate
              };
            } else {
              // If no immediate previous build, find the closest lower build
              const allLowerVersions = dataForValidationFolder
                .filter(item => compareBuildNumbersDescending(item.buildNumber, currentVersion) > 0)
                .sort((a, b) => compareBuildNumbersDescending(a.buildNumber, b.buildNumber));

              if (allLowerVersions.length > 0) {
                // Return the highest of the lower versions
                return {
                  BuildId: allLowerVersions[0].id,
                  isFolder: true,
                  isOpen: false,
                  path: entry.path,
                  buildNumber: allLowerVersions[0].buildNumber,
                  srcPath: allLowerVersions[0].parameters.srcFolder,
                  lastChangedDate: allLowerVersions[0].lastChangedDate
                };
              }
            }

            // If no previous build is found, return null or an empty object
            return null;
          }).filter(data => data !== null); // Remove any null entries

          // Helper functions

          function isNewerBuildNumber(newBuildNumber, currentBuildNumber) {
            return getVersionNumber(newBuildNumber) > getVersionNumber(currentBuildNumber);
          }

          function compareBuildNumbersDescending(buildNumberA, buildNumberB) {
            return getVersionNumber(buildNumberB) - getVersionNumber(buildNumberA);
          }

          function getVersionNumber(buildNumber) {
            return parseInt(buildNumber.split('.').pop());
          }

          function findImmediatePreviousBuildNumber(currentBuildNumber) {
            const currentVersionNumber = getVersionNumber(currentBuildNumber);
            const possiblePreviousNumber = currentVersionNumber - 1;

            // Look for the immediate previous build number
            const previousBuild = dataForValidationFolder.find(item => {
              return getVersionNumber(item.buildNumber) === possiblePreviousNumber &&
                item.buildNumber.startsWith(currentBuildNumber.split('.').slice(0, -1).join('.'));
            });

            return previousBuild;
          }
          // Map and filter to get only non-empty and defined BuildId values
          window.buildIDArray = extractedData.map(items => items.BuildId).filter(buildId => buildId !== undefined && buildId !== '');

          // Now window.buildIDArray will contain only defined and non-empty BuildId values
          if (index.includes('Archive')) {
            setNestedData(extractedData);
          }
          hideLoading();
          window.folderName = index;
        } else if (BuildId) {
          window.folderName = index;
          const result = await approvalForPrerelease(dataForValidationFolder, BuildId, instance, accounts);
          if (result.showMessage === true) {
            setIsErrorPopupOpen(true); // Toggle the state
            setMessage(result.message);
            hideLoading();
            document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
            return;
          }
          const dataForthe = index.split('/').pop();
          const uniqueFilteredData = result
            .map(item => {
              if (item.relativePath.includes(dataForthe)) {
                const dataSplit = item.relativePath.split(dataForthe);
                const dataSlitIndex = index.split(dataForthe);
                const dataSplitS = dataSplit[1].split('/');
                const isFolder = dataSplitS[1] && !dataSplitS[2] ? false : true;
                return {
                  BuildId: BuildId,
                  isFolder: isFolder,
                  isOpen: false,
                  path: index + "/" + dataSplitS[1],
                  buildNumber: '',
                  srcPath: '',
                  fileContent: item.fileContent,
                  lastChangedDate: lastChangedDate
                };
              }
              // Ensure to return null or undefined for items that don't match the condition
              return null; // or undefined
            })
            .filter((item, index, array) => {
              // Check if item is defined and has a path property
              if (item) {
                // Use Array.findIndex to check for first occurrence of item.path
                return index === array.findIndex(obj => {
                  if (obj) {
                    return obj.path === item.path;
                  }
                });
              }
              // If item is undefined or doesn't have a path property, filter it out
              return false;
            });
          if (index.includes('Archive')) {
            setNestedData(uniqueFilteredData);
          }
          hideLoading();
        }
      } else if (index.includes('Validation')) {

        showLoading();
        let dataForValidationFolder = await getBuildsForPipeline(process.env.REACT_APP_ValidationPipeline, instance, accounts, index.includes('/Archive') ? index.replaceAll("/Archive", '') : index, branchName);
        if (dataForValidationFolder.showMessage === true) {
          setIsErrorPopupOpen(true); // Toggle the state
          setMessage(dataForValidationFolder.message);
          hideLoading();
          document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
          return;
        }
        if (srcPath) {
          window.srcFolder = srcPath;
        }
        if (index.split('/').pop().includes('Validation') || index.split('/').pop().includes('Archive') && !BuildId) {
          // Step 1: Extract and sort data to get the latest builds
          let extractedData = dataForValidationFolder.reduce((acc, data) => {
            // Check if entry already exists for the destinationFolder
            const existingEntry = acc.find(entry => entry.path === data.parameters.destinationFolder);

            // If no entry exists or the new buildNumber is newer, replace or add the entry
            if (!existingEntry || isNewerBuildNumber(data.buildNumber, existingEntry.buildNumber)) {
              acc = acc.filter(entry => entry.path !== data.parameters.destinationFolder); // Remove existing entry if any
              acc.push({
                BuildId: data.id,
                isFolder: true,
                isOpen: false,
                path: data.parameters.destinationFolder,
                buildNumber: data.buildNumber,
                srcPath: data.parameters.srcFolder,
                lastChangedDate: data.lastChangedDate
              });
            }

            return acc;
          }, []);

          // Sort extractedData by buildNumber descending to get the latest versions first
          extractedData.sort((a, b) => compareBuildNumbersDescending(a.buildNumber, b.buildNumber));

          // Step 2: Determine the immediate previous build and handle cases with gaps for each entry in extractedData
          let previousVersionsData = extractedData.map(entry => {
            const currentVersion = entry.buildNumber;
            const immediatePreviousBuild = findImmediatePreviousBuildNumber(currentVersion);

            if (immediatePreviousBuild) {
              // If immediate previous build found, return it
              return {
                BuildId: immediatePreviousBuild.id,
                isFolder: true,
                isOpen: false,
                path: entry.path,
                buildNumber: immediatePreviousBuild.buildNumber,
                srcPath: immediatePreviousBuild.parameters.srcFolder,
                lastChangedDate: immediatePreviousBuild.lastChangedDate
              };
            } else {
              // If no immediate previous build, find the closest lower build
              const allLowerVersions = dataForValidationFolder
                .filter(item => compareBuildNumbersDescending(item.buildNumber, currentVersion) > 0)
                .sort((a, b) => compareBuildNumbersDescending(a.buildNumber, b.buildNumber));

              if (allLowerVersions.length > 0) {
                // Return the highest of the lower versions
                return {
                  BuildId: allLowerVersions[0].id,
                  isFolder: true,
                  isOpen: false,
                  path: entry.path,
                  buildNumber: allLowerVersions[0].buildNumber,
                  srcPath: allLowerVersions[0].parameters.srcFolder,
                  lastChangedDate: allLowerVersions[0].lastChangedDate
                };
              }
            }

            // If no previous build is found, return null or an empty object
            return null;
          }).filter(data => data !== null); // Remove any null entries

          // Helper functions

          function isNewerBuildNumber(newBuildNumber, currentBuildNumber) {
            return getVersionNumber(newBuildNumber) > getVersionNumber(currentBuildNumber);
          }

          function compareBuildNumbersDescending(buildNumberA, buildNumberB) {
            return getVersionNumber(buildNumberB) - getVersionNumber(buildNumberA);
          }

          function getVersionNumber(buildNumber) {
            return parseInt(buildNumber.split('.').pop());
          }

          function findImmediatePreviousBuildNumber(currentBuildNumber) {
            const currentVersionNumber = getVersionNumber(currentBuildNumber);
            const possiblePreviousNumber = currentVersionNumber - 1;

            // Look for the immediate previous build number
            const previousBuild = dataForValidationFolder.find(item => {
              return getVersionNumber(item.buildNumber) === possiblePreviousNumber &&
                item.buildNumber.startsWith(currentBuildNumber.split('.').slice(0, -1).join('.'));
            });

            return previousBuild;
          }


          if (index.split('/').pop() === 'Archive') {
            function updatePaths(data) {
              return data.map(item => {
                let pathParts = item.path.split('/');

                // Find the index of 'ValidationRelease' in pathParts
                let validationIndex = pathParts.findIndex(part => part.startsWith('ValidationRelease'));

                if (validationIndex !== -1) {
                  // 'ValidationRelease' is found, insert 'Archive' after it
                  let versionPart = pathParts[validationIndex]; // Get the version part
                  let versionIndex = versionPart.indexOf('ValidationRelease') + 'ValidationRelease'.length; // Get the index where version starts

                  // Extract version number
                  let versionNumber = versionPart.substring(versionIndex);

                  // Insert 'Archive' after the version number
                  pathParts.splice(validationIndex + 1, 0, 'Archive');
                } else {
                  // Find the index of 'Prerelease' in pathParts
                  let prereleaseIndex = pathParts.findIndex(part => part === 'Prerelease');

                  if (prereleaseIndex !== -1) {
                    // 'Prerelease' is found, insert 'Archive' after it
                    pathParts.splice(prereleaseIndex + 1, 0, 'Archive');
                  } else {
                    // Handle cases where neither 'ValidationRelease' nor 'Prerelease' is found
                    console.error(`Unable to update path for item: ${JSON.stringify(item)}`);
                    return item;
                  }
                }

                // Join the updated path parts back into a string
                item.path = pathParts.join('/') + '.zip';
                item.isFolder = false;
                return item;
              });
            }


            // Updating paths in the array
            previousVersionsData = updatePaths(previousVersionsData);
            if (index.includes('Archive')) {
              setNestedData(previousVersionsData);
            }
            window.buildIDArray = previousVersionsData.map(items => items.BuildId).filter(buildId => buildId !== undefined && buildId !== '');
            hideLoading();
          } else {
            extractedData.push({
              BuildId: '',
              isFolder: true,
              isOpen: false,
              path: `${index}/Archive`,
              buildNumber: '',
              srcPath: '',
              lastChangedDate: ''
            });
            // Assuming extractedData is an array of objects containing BuildId properties

            // Map and filter to get only non-empty and defined BuildId values
            window.buildIDArray = extractedData.map(items => items.BuildId).filter(buildId => buildId !== undefined && buildId !== '');
            if (index.includes('Archive')) {
              setNestedData(extractedData);
            }
            hideLoading();
          }

        } else if (BuildId) {
          window.folderName = index;
          showLoading();
          const result = await approvalForPrerelease(dataForValidationFolder, BuildId, instance, accounts);
          if (result.showMessage === true) {
            setIsErrorPopupOpen(true); // Toggle the state
            setMessage(result.message);
            hideLoading();
            document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
            return;
          }
          const dataForthe = index.split('/').pop();
          const uniqueFilteredData = result
            .map(item => {
              if (item.relativePath.includes(dataForthe)) {
                const dataSplit = item.relativePath.split(dataForthe);
                const dataSlitIndex = index.split(dataForthe);
                const dataSplitS = dataSplit[1].split('/');
                const isFolder = dataSplitS[1] && !dataSplitS[2] ? false : true;
                return {
                  BuildId: BuildId,
                  isFolder: isFolder,
                  isOpen: false,
                  path: index + "/" + dataSplitS[1],
                  buildNumber: '',
                  srcPath: '',
                  fileContent: item.fileContent,
                  lastChangedDate: lastChangedDate
                };
              }
              // Ensure to return null or undefined for items that don't match the condition
              return null; // or undefined
            })
            .filter((item, index, array) => {
              // Check if item is defined and has a path property
              if (item) {
                // Use Array.findIndex to check for first occurrence of item.path
                return index === array.findIndex(obj => {
                  if (obj) {
                    return obj.path === item.path;
                  }
                });
              }
              // If item is undefined or doesn't have a path property, filter it out
              return false;
            });
          if (index.includes('Archive')) {
            setNestedData(uniqueFilteredData);
          }
          hideLoading();
        }
      }
      if (!index.includes('Archive') || index.includes("Submission")) {
        window.Previousfolder = window.folderName;
        window.buildIDRender = '';
        if (index.includes("/Submission/")) {
          window.srcFolder = index;
        }
        showLoading();
        let dataRecivedFromBlob = await listBlobsInContainer(branchName.toLowerCase(), index);
        if (dataRecivedFromBlob.error) {
          setNestedData();
          if (event) {
            event.stopPropagation();
          }
          hideLoading();
          return;
        }
        const metaDataResult = await getBlobMetadata(branchName.toLowerCase(), index + '/');
        if (metaDataResult) {
          if (metaDataResult.error) {
            if (!dataRecivedFromBlob) {
              return { error: true }
            }
          } else if (!index.split('/').pop().includes('Validation') && !index.split('/').pop().includes('Prerelease') && !index.split('/').pop().includes('BusinessRelease')) {

            if (((index.includes('Validation') || index.includes('Prerelease') || index.includes('BusinessRelease')) && !index.includes('Archive'))) {
              dataRecivedFromBlob = dataRecivedFromBlob.map(item => ({ ...item, BuildId: parseInt(metaDataResult.buildid ? metaDataResult.buildid : window.buildIDRender, 10) }));
              window.buildIDRender = metaDataResult.buildid ? metaDataResult.buildid : window.buildIDRender;
            } else {
              window.buildIDRender = "";
            }
          } else {
            window.buildIDRender = "";
          }
        } else {
          window.buildIDRender = "";
        }
        handelComponentErrors(dataRecivedFromBlob);
        // Function to determine the sort index
        const getSortIndex = (path) => {
          const pathName = path.split('/').pop();

          // Check for "Submission" first
          if (pathName.includes('Submission')) return 0;

          // If "Submission" is not present, perform other checks
          if (pathName.includes('ValidationRelease')) return 1;
          if (pathName.includes('Prerelease')) return 2;
          if (pathName.includes('BusinessRelease')) return 3;
          if (pathName.includes('BusinessReleaseDocs')) return 4;

          return 5; // Default case if none of the above match
        };

        // Check if any item contains "Submission"
        const hasSubmission = dataRecivedFromBlob.some(item => item.path.split('/').pop().includes('Prerelease'));

        // If "Submission" is present, sort the array based on custom order
        if (hasSubmission) {
          dataRecivedFromBlob.sort((a, b) => getSortIndex(a.path) - getSortIndex(b.path));
        }
        setNestedData(dataRecivedFromBlob);
        if (event) {
          event.stopPropagation();
        }
        hideLoading();
      }
      if (nestedData && document.getElementById(branchName + index.replace('/', '') + "arrowMark")) {
        document.getElementById(branchName + index.replace('/', '') + "arrowMark").style.transform = 'rotate(90deg)';
      }
    }

    document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');

  };

  const downloadSingleFile = async (filePath, event, BuildId, fileName, fileContent) => {
    document.getElementById('AppContainer')?.classList?.add('excluxivityEnable');
    showLoading();
    const fileExtension = filePath.split('.').pop().toLowerCase();
    // Define the condition to check for text-based file formats
    const isTextFile = (
      fileExtension === 'txt' ||
      fileExtension === 'md' ||
      fileExtension === 'log' ||
      fileExtension === 'js' ||
      fileExtension === 'css' ||
      fileExtension === 'html' ||
      fileExtension === 'xml' ||
      fileExtension === 'json' ||
      fileExtension === 'yaml' ||
      fileExtension === 'yml' ||
      fileExtension === 'csv' ||
      fileExtension === 'ini' ||
      fileExtension === 'cfg' ||
      fileExtension === 'config' ||
      fileExtension === 'rtf' ||
      fileExtension === 'htm' ||
      fileExtension === 'py' ||
      fileExtension === 'java' ||
      fileExtension === 'cpp' ||
      fileExtension === 'c' ||
      fileExtension === 'bat' ||
      fileExtension === 'sh' ||
      fileExtension === 'svg'
    );
    if (filePath.includes('Submission') && (isTextFile || fileExtension === 'pdf')) {
      const data = await getBlobContent(branchName.toLowerCase(), filePath);
      handelComponentErrors(data);

      if (isTextFile) {
        setfileContentView(data);
        setshowFileContent(true);
        hideLoading();
      } else if (fileExtension === 'pdf') {
        window.open(data, '_blank');
        hideLoading();
      }
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      return;
    } else if (filePath.includes('Submission')) {
      showLoading();
      errorResult = await downloadBlob(branchName.toLowerCase(), filePath.includes('.zip') && filePath.includes('Archive') ? filePath + '/' : filePath);
      handelComponentErrors(errorResult);
      hideLoading();
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      return;
    }
    if ((isTextFile || fileExtension === 'pdf') && fileContent) {
      if (isTextFile) {
        setfileContentView(fileContent);
        setshowFileContent(true);
      } else if (fileExtension === 'pdf') {
        window.open(fileContent, '_blank');
      }
    } else {
      event.stopPropagation();
      if (BuildId) {
        const downloadURL = await getArtifactFromBuild(BuildId, instance, accounts);
        if (downloadURL.showMessage === true) {
          setMessage(responceData.message);
          setIsErrorPopupOpen(prev => !prev); // Toggle the state
          return;
        }
        if (isTextFile || fileExtension === 'pdf') {
          const responceData = await downloadUrlDataAndFetchFile(downloadURL, instance, accounts, fileName,);
          if (isTextFile) {
            setfileContentView(responceData);
            setshowFileContent(true);
            hideLoading();
            return
          } else if (fileExtension === 'pdf') {
            hideLoading();
            window.open(responceData, '_blank');
            return
          }
        }

        const responceData = await downloadUrlData(downloadURL, fileName.includes('.zip') ? null : fileName, branchName, fileName.includes('.zip') ? filePath : fileName, null, instance, accounts);
        if (responceData.showMessage === true) {
          setMessage(responceData.message);
          setIsErrorPopupOpen(prev => !prev); // Toggle the state
        }
      } else {
        const response = await singleFileDownload(branchName, filePath, instance, accounts);
        if (response.showMessage === true) {
          setMessage(response.message);
          setIsErrorPopupOpen(prev => !prev); // Toggle the state
        }
        if (response && isTextFile) {
          setfileContentView(response);
          setshowFileContent(true);
        } else if (fileExtension === 'pdf' && response) {
          window.open(response, '_blank');
        }
      }
    }


    document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
    hideLoading();
  }

  function modifyString(path) {
    const segments = path.split('/');
    segments.pop();
    const newPath = segments.join('/');
    return newPath;
  }

  const handleBackClick = () => {
    if (window.refreshCurrentPage !== '') {
      setTriggerFetch(prevState => !prevState);
      if (modifyString(window.refreshCurrentPage.index)) {
        handleDoubleClick(modifyString(window.refreshCurrentPage.index), window.refreshCurrentPage.BuildId, window.refreshCurrentPage.srcPath, null, window.refreshCurrentPage.lastChangedDate, branchName);
      } else {
        handleFileClick(branchName);
      }
    }
  };

  const handleRefreshClick = () => {
    if (window.refreshCurrentPage !== '') {
      setTriggerFetch(prevState => !prevState);
      handleDoubleClick(window.refreshCurrentPage.index, window.refreshCurrentPage.BuildId, window.refreshCurrentPage.srcPath, null, window.refreshCurrentPage.lastChangedDate, branchName);
    } else {
      handleFileClick(branchName);
    }
  };



  const fetchData = async (branchNameRecived, folderPathRecived, returnFolderInfoRecived) => {
    const returnFolderInfo = returnFolderInfoRecived;
    if (branchNameRecived) {
      try {
        showLoading();
        const url = process.env.REACT_APP_BASE_URL + `items?scopePath=/${folderPathRecived}&recursionLevel=OneLevel&versionDescriptor.version=${branchNameRecived}&commitDate=true&api-version=7.1-preview.1`;
        document.getElementById('AppContainer')?.classList?.add('excluxivityEnable');
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${await accessToken(instance, accounts)}`,
          }
        });
        if (response.status === 200) {
          const dataReceived = response.data.value;
          async function getThelatestDate(itemPath, branchNameRecived) {
            try {
              const urlFordate = `https://dev.azure.com/${process.env.REACT_APP_AUTH_CLIENT_ID}/${process.env.REACT_APP_PUBLIC_KEY}/_apis/git/repositories/${process.env.REACT_APP_REPOSITORY}/commits?searchCriteria.itemPath=${encodeURIComponent(itemPath)}&searchCriteria.itemVersion.version=${branchNameRecived}&$top=1&$orderby=commitDate desc&api-version=7.1`;
              const responseFordate = await axios.get(urlFordate, {
                headers: {
                  Authorization: `Bearer ${await accessToken(instance, accounts)}`,
                }
              });
              if (responseFordate.data.value.length > 0) {
                return formatDate(responseFordate.data.value[0].author.date);
              }
            } catch (error) {
              hideLoading();
              console.error('Error fetching latest date:', error);
              throw error; // Propagate error for further handling
            }
          }
          // Assuming dataReceived is an array of objects with path and isFolder properties
          const filteredDataReceivedPromises = dataReceived.map(async ({ path, isFolder }, index) => {
            try {
              if (path === '/' || path.includes("/") && index === 0 || path.includes(".gitignore")) {
                return null;
              }
              const cleanedPath = path.replace(/^\/|\/$/g, '');
              const lastChangedDate = await getThelatestDate(cleanedPath, branchNameRecived);
              return {
                path: cleanedPath,
                isFolder: isFolder !== undefined ? isFolder : false,
                isOpen: false,
                BuildId: '',
                buildNumber: '',
                srcPath: '',
                lastChangedDate: lastChangedDate
              };
            } catch (error) {
              hideLoading();
              console.error('Error processing path:', path, error);
              return null; // Return null for failed items
            }
          });

          // Wait for all promises to resolve
          Promise.all(filteredDataReceivedPromises)
            .then(filteredDataReceived => {
              // Filter out null values (failed items)
              filteredDataReceived = filteredDataReceived.filter(obj => obj !== null);

              // Function to determine the sort index
              const getSortIndex = (path) => {
                const pathName = path.split('/').pop();

                // Check for "Submission" first
                if (pathName.includes('Submission')) return 0;

                // If "Submission" is not present, perform other checks
                if (pathName.includes('ValidationRelease')) return 1;
                if (pathName.includes('Prerelease')) return 2;
                if (pathName.includes('BusinessRelease')) return 3;
                if (pathName.includes('BusinessReleaseDocs')) return 4;

                return 5; // Default case if none of the above match
              };

              // Check if any item contains "Submission"
              const hasSubmission = filteredDataReceived.some(item => item.path.split('/').pop().includes('Prerelease'));

              // If "Submission" is present, sort the array based on custom order
              if (hasSubmission) {
                filteredDataReceived.sort((a, b) => getSortIndex(a.path) - getSortIndex(b.path));
              }


              setNestedData(filteredDataReceived);
              setTriggerFetch(false);
              hideLoading();
              document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');


            })
            .catch(error => {
              console.error('Error processing filtered data:', error);
              // Handle errors if necessary
              hideLoading();
            });
        } else {
          hideLoading();
          // setMessage(`An unknown error occurred while fetching selected ${branchName} platform data (Status Code: ${response.status}). Please refresh the page or verify platform availability on DevOps.`);
          // setIsErrorPopupOpen(true);
          document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        }

      } catch (error) {
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        setNestedData(null);
        setTriggerFetch(false);
        // setMessage(`An unknown error occurred while fetching selected ${branchName} platform data. Please refresh the page or verify platform availability on DevOps.`);
        // setIsErrorPopupOpen(true); 
      }

    }
  };




  const handleClickofCreateNewFolder = () => {
    setShowCreateFolderForm(true);
  };

  const handleClickofUploadFiles = () => {
    setshowUploadFiles(true);
  };

  const handleClickofdownloadFile = async () => {
    if (folderPath.includes('Submission')) {
      showLoading();
      errorResult = await downloadBlob(branchName.toLowerCase(), folderPath + '/');
      handelComponentErrors(errorResult);
      if (errorResult) {
        if (errorResult.showMessage) {
          setMessage("Software downloaded successfully!");
          setIsErrorPopupOpen(prev => !prev); // Toggle the state
        }
      }
      hideLoading();
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      return;
    }


    showLoading();
    const responceData = await DownloadContent(folderPath, branchName, instance, accounts);
    if (responceData.showMessage === true) {
      setMessage(responceData.message);
      setIsErrorPopupOpen(prev => !prev); // Toggle the state
    }
    hideLoading();
  };

  function getElementFromDom(branchKey, nestedKey) {
    if (nestedKey) {
      return document.getElementById(nestedKey);
    } else {
      return document.getElementById(branchKey);
    }
  }

  function hasChildWithBorder(element, borderValue) {
    const children = element.children;
    for (let i = 0; i < children.length; i++) {
      const computedStyle = window.getComputedStyle(children[i]);
      const border = computedStyle.border;

      // Compare border value with borderValue
      if (border === borderValue) {
        return true;
      }
    }
    return false;
  }
  // minimize and maximiz the data for the folder nested
  const minimize = (getElementFolder, BuildId, srcPath, event, lastChangedDate, updatedBranchName) => {
    const nestedKey = getElementFolder !== "" ? branchName + getElementFolder.replace('/', '') + "nested" : "";
    let targetElement = getElementFromDom('', nestedKey);
    const existingWrapper = targetElement.nextElementSibling;
    if (existingWrapper && existingWrapper?.classList?.contains('nestedWrapper')) {


      // document.getElementById(getElementFolder.replace('/', '') + "arrowMark").style.transform = '';
      // existingWrapper.parentNode.removeChild(existingWrapper);
      if (existingWrapper && existingWrapper?.classList?.contains('d-none')) {
        existingWrapper?.classList?.remove('d-none');
        document.getElementById(branchName + getElementFolder.replace('/', '') + "arrowMark").style.transform = 'rotate(90deg)';
        const hasActiveChild = existingWrapper.querySelector('.active');
        if (hasActiveChild) {
          targetElement?.classList?.remove('active');
        }
      } else {
        existingWrapper?.classList?.add('d-none');
        document.getElementById(branchName + getElementFolder.replace('/', '') + "arrowMark").style.transform = '';
        const hasActiveChild = existingWrapper.querySelector('.active');
        if (hasActiveChild) {
          targetElement?.classList?.add('active');
        }
      }
    } else {
      handleDoubleClick(getElementFolder, BuildId, srcPath, event, lastChangedDate, updatedBranchName);
    }
  }
  const handelnestedChildTotheBranch = async (branchName, folderPath) => {
    const branchKey = branchName !== "" ? branchName + "branch" : "";
    const nestedKey = folderPath !== "" ? branchName + folderPath.replace('/', '') + "nested" : "";
    if (branchKey) {
      // var elements = document.getElementsByClassName('nestedWrapper');
      // while (elements.length > 0) {
      //     elements[0].parentNode.removeChild(elements[0]);
      // }
      if (folderPath) {
        const nestedFiles = document.querySelectorAll('.file');
        nestedFiles.forEach(file => {
          file?.classList?.remove('active');
        });
      }
      document.getElementById(`plusMark` + branchName).style.transform = 'rotate(90deg)';
      if (nestedData && nestedData.length > 0 && document.getElementById(branchName + folderPath.replace('/', '') + "arrowMark")) {
        const hasFolder = nestedData.some(item => item.isFolder);
        if (hasFolder) {
          document.getElementById(branchName + folderPath.replace('/', '') + "arrowMark").style.transform = 'rotate(90deg)';
        } else {
          const element = document.getElementById(branchName + folderPath.replace('/', '') + "arrowMark");
          if (element) {
            const firstChild = element.firstElementChild;
            if (firstChild) {
              firstChild?.classList?.add('d-noneArrow');
            }
          }
        }
      } else {
        const element = document.getElementById(branchName + folderPath.replace('/', '') + "arrowMark");
        if (element) {
          const firstChild = element.firstElementChild;
          if (firstChild) {
            firstChild?.classList?.add('d-noneArrow');
          }
        }
      }
    }
    // Find the DOM element based on either branchKey or nestedKey
    let targetElement = getElementFromDom(branchKey, nestedKey);

    // Remove border from all elements with class 'nestedFile'
    const nestedFiles = document.querySelectorAll('.nestedFile');
    nestedFiles.forEach(file => {
      file?.classList?.remove('active');
    });

    if (targetElement) {
      if (folderPath) {
        targetElement?.classList?.add('active');;
      }
      const existingWrapper = targetElement.nextElementSibling;
      if (existingWrapper && existingWrapper?.classList?.contains('nestedWrapper')) {
        existingWrapper.parentNode.removeChild(existingWrapper);
      }
      // Create a wrapper element for the new content with margin-left
      const wrapper = document.createElement('div');
      wrapper?.classList?.add('nestedWrapper');
      wrapper.style.marginLeft = '1.63vw'; // Apply margin-left of 20px

      // Create a container element for the new content
      const container = document.createElement('div');

      // Use createRoot to render JSX content inside the container
      const root = createRoot(container); // Use createRoot instead of ReactDOM.createRoot
      root.render(
        <div className="nestedFiles">
          {userRole && nestedData && nestedData.map((nestedFile, nestedIndex) => (
            nestedFile.isFolder && (
              (nestedFile.path.includes('BusinessRelease') || nestedFile.path.includes('Submission') || nestedFile.path.includes('Prerelease') || nestedFile.path.includes('Validation')) ?
                userRole.foldersForModuleOwner(nestedFile) : true) && (
              <div
                key={branchName + nestedFile.path.replace('/', '') + "nested"}
                className="nestedFile"
                onClick={(event) => {
                  event.stopPropagation()
                  handleDoubleClick(nestedFile.path, nestedFile.BuildId, nestedFile.srcPath, event, nestedFile.lastChangedDate, branchName)
                }}
                id={branchName + nestedFile.path.replace('/', '') + "nested"}
              >
                <span
                  className='arrowMarkClick'
                  onClick={(event) => {
                    event.stopPropagation(); // Prevent event from bubbling up
                    minimize(nestedFile.path, nestedFile.BuildId, nestedFile.srcPath, event, nestedFile.lastChangedDate, branchName);
                  }}
                  id={branchName + nestedFile.path.replace('/', '') + "arrowMark"}
                >
                  <KeyboardArrowRightIcon sx={{
                    fontSize: '3vh',
                    color: 'rgba(0, 0, 0, 0.70)'  // This sets the color to a lighter shade of black (default Material-UI text secondary color)
                  }} />
                </span>
                <span className='folderLogo'><FolderIcon style={{ fontSize: 'inherit', color: 'inherit' }} /></span>
                <span title={formatPathLabel(nestedFile.path.split('/').pop())} className='folderNameLeftSide'>
                  {formatPathLabel(nestedFile.path.split('/').pop())}
                </span>
              </div>
            )
          ))}
        </div>
      );

      // Append the container element to the wrapper
      wrapper.appendChild(container);

      // Append the wrapper as the second child of the targetElement
      if (targetElement.nextSibling) {
        // If targetElement has a next sibling, insert before it
        targetElement.parentNode.insertBefore(wrapper, targetElement.nextSibling);
      } else {
        // If targetElement has no next sibling, simply append to parent
        targetElement.parentNode.appendChild(wrapper);
      }
    }
    // Check if the element with id 'Rejectbutton' exists
    var rejectButton = document.getElementById('Rejectbutton');
    if (rejectButton) {
      // If the element exists, remove the 'd-none' class
      rejectButton?.classList?.add('d-none');
    }

    // Check if the element with id 'Approvebutton' exists
    var approveButton = document.getElementById('Approvebutton');
    if (approveButton) {
      // If the element exists, remove the 'd-none' class
      approveButton?.classList?.add('d-none');
    }
    if (folderPath) {
      if (folderPath.split("/").pop() !== 'Submission' && folderPath.split("/").pop() !== 'PreRelease' && folderPath.split("/").pop() !== 'BusinessRelease' && !folderPath.includes("BusinessReleaseDocs") && !folderPath.includes("Archive") && (userRole.canApprove() || (userRole.getCurrentUser() === process.env.REACT_APP_UR_M_O)) && folderPath.split("/").pop() !== 'Archive' && folderPath.includes("Submission") || folderPath.includes("Validation") || folderPath.includes("Prerelease") && !folderPath.includes("BusinessRelease")) {
        // const responceData = await BuildDetails(folderPath, branchName, instance, accounts);
        showLoading();
        const responceData = await getFolderBlobMetadata(branchName.toLowerCase(), folderPath);
        handelComponentErrors(responceData);
        if (responceData != undefined) {
          if (responceData.metadata == '') {
            setMessage(responceData.message);
            setIsErrorPopupOpen(prev => !prev);
          } else if (Object.keys(responceData.metadata).length !== 0 && responceData.metadata.status === "waiting" || responceData.metadata.status === "inprogress") {
            document.getElementById('Rejectbutton')?.classList?.remove('d-none');
            document.getElementById('Approvebutton')?.classList?.remove('d-none');
            document.getElementById('UploadButton')?.classList?.add('d-none');
            document.getElementById('CreateFolderButton')?.classList?.add('d-none');
            if (!userRole.canApprove() || responceData.metadata.status === "inprogress") {
              document.getElementById('Inprogress')?.classList?.remove('d-none');
              document.getElementById('UploadButton')?.classList?.add('d-none');
              document.getElementById('Rejectbutton')?.classList?.add('d-none');
              document.getElementById('Approvebutton')?.classList?.add('d-none');
            }
          } else if (responceData.metadata) {
            if (responceData.metadata.status === "rejected") {
              document.getElementById('Rejected')?.classList?.remove('d-none');
              document.getElementById('Approved')?.classList?.add('d-none');
              document.getElementById('UploadButton')?.classList?.remove('d-none');
              document.getElementById('Inprogress')?.classList?.add('d-none');
            } else if (responceData.metadata.status === "approved") {
              document.getElementById('Rejected')?.classList?.add('d-none');
              document.getElementById('Approved')?.classList?.remove('d-none');
              document.getElementById('Inprogress')?.classList?.add('d-none');
              document.getElementById('UploadButton')?.classList?.remove('d-none');
            }
          }
        }
        hideLoading();
      } else {
        document.getElementById('UploadButton')?.classList?.remove('d-none');
        document.getElementById('CreateFolderButton')?.classList?.remove('d-none');
      }
    }
  };

  function formatPathLabel(fileName) {
    return fileName.replace(/(Prerelease|ValidationRelease(\d*\.\d*)|BusinessReleaseDocs|BusinessRelease)/g, (match) => {
      if (match.startsWith('Prerelease')) return 'Pre Release';
      if (match.startsWith('ValidationRelease')) return `Validation Release ${match.replace('ValidationRelease', '')}`;
      if (match.startsWith('BusinessReleaseDocs')) return 'Business Release Docs';
      if (match.startsWith('BusinessRelease')) return 'Business Release';
      return match;
    });
  }



  const formatDate = (isoDate) => {
    const date = new Date(isoDate);

    // Extract date components
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedHours = hours.toString().padStart(2, '0');

    // Create and return the formatted date object
    const formattedDate = {
      date: `${day}-${month}-${year}`,
      time: `${formattedHours}:${minutes}`,
      ampm: ampm
    };

    return formattedDate;
  };


  function checkFileFormat(data) {
    // Define the digits you want to check for
    let validDigits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    // Check if the data starts with one of the valid digits
    if (validDigits.includes(data.charAt(0))) {
      return 'File Folder';
    } else {
      // Handle the case where the data does not start with 1, 2, 3, or 4
      return data + ' File';  // Or whatever your logic requires
    }
  }


  useEffect(() => {
    if (userRole) {
      handelnestedChildTotheBranch(window.branchUpdate, window.folderPath);
    }
  }, [nestedData]);

  // format data for show on the addreess bar current address showing logic
  function formatPath(branchName, folderPath) {

    if ((!nestedData || nestedData.length === 0) && folderPath) {
      return `${branchName} > ${folderPath.replaceAll("/", " > ")}`;
    }
    if ((!nestedData || nestedData.length === 0) && !folderPath) return branchName; // Handle empty data case
    // Determine if the last item is a folder
    const hasFolder = nestedData.some(item => item.isFolder);
    return `${branchName} > ${folderPath ? folderPath.replaceAll("/", " > ") : ''}${hasFolder && folderPath ? ' > ' : ''}`;
  }



  // send approval
  const sendApproval = async (approvalStatus, approvalId) => {
    showLoading();
    if (approvalStatus === "approved") {
      const newMetadata = {
        status: 'approved',
        userName: 'value2',
        comment: ''
      };
      const data = await listBlobsInContainer(branchName.toLowerCase(), folderPath.split('/')[0]);
      handelComponentErrors(data);
      setshowApproveButtonPup({ data: data, approvalId: approvalId, approvalStatus: approvalStatus });
    } else {
      const newMetadata = {
        status: 'rejected',
        userName: 'value2',
        comment: ''
      };
      errorResult = await updateBlobMetadata(branchName.toLowerCase(), folderPath + '/', newMetadata);
      handelComponentErrors(errorResult);
      const response = await sendCustomEmail('failure', branchName, window.srcFolder, instance, accounts, "", "", folderPath);
      if (response.showMessage) {
        console.log(response);
      }
      setMessage("Software rejected at " + branchName + '/' + folderPath);
      setIsErrorPopupOpen(prev => !prev); // Toggle the state
    }
    hideLoading();
    return;
  };


  return (
    <AuthenticatedTemplate>
      <div className='inputField'>
        {userRole.canCreateFolder() && <div className="Directoryword"><span className='directoryWordCss' onClick={() => userRole.canCreateFolder() ? setShowCreateFolderFormS() : null}><span className='display-add-new-program'>Add New Program</span> <LibraryAddIcon className='icon-outlined display-add-new-program_icon' /></span></div>}
        {!userRole.canCreateFolder() && <div className="Directoryword"><span className='directoryWordCss' >Programs</span></div>}
        <div style={{ display: 'flex', justifyContent: 'row' }}>
          {branchName && <div className="buttonFor  goUpword" onClick={handleBackClick}><ArrowUpwardIcon className='icon-filled' /><ArrowUpwardOutlinedIcon className='icon-outlined' /></div>}
          {branchName && <div className="buttonFor  pageRefresh" onClick={handleRefreshClick}><RefreshIcon className='icon-filled' /><RefreshOutlinedIcon className='icon-outlined' /></div>}
        </div>
        <div className="left">
          <div id="currentFolderpath" className={branchName ? "currentFolderPathCSS" : ''}>
            {branchName && (
              <span className='marginForactualName' title={formatPathLabel(formatPath(branchName, folderPath))}>
                {formatPathLabel(formatPath(branchName, folderPath))}
              </span>
            )}
          </div>
        </div>
        <div className='divForUploadButton'>
          {<div className='d-none rejected-text' id='Rejected'>Rejected</div>}
          {<div className='d-none approved-text' id='Approved'>Approved</div>}
          {<div className='d-none Inprogress-text' id='Inprogress'>Inprogress</div>}
          {branchName && userRole && userRole.canApprove() && <div className='buttonFor d-none ApprovebuttonCss' id='Approvebutton'><span className='upload-btn-icon' id={`${approvalID}+approve`} onClick={() => sendApproval('approved')}>&#10003;</span></div>}
          {branchName && userRole && userRole.canApprove() && <div className='buttonFor d-none RejectbuttonCss' id='Rejectbutton'> <span className='upload-btn-icon' id={`${approvalID}+Reject`} onClick={() => sendApproval('rejected')}>&#10007;</span></div>}
          {branchName && userRole && userRole.canCreateFolder(folderPath) && <div className='buttonFor CreateFolder-btn ' id='CreateFolderButton' onClick={handleClickofCreateNewFolder}><CreateNewFolderIcon className='icon-filled' /><CreateNewFolderOutlinedIcon className='icon-outlined' /></div>}
          {branchName && userRole && userRole.canUpload(folderPath) && <div className='buttonFor Upload-btn' id='UploadButton' onClick={handleClickofUploadFiles}><CloudUploadIcon className='icon-filled' /><CloudUploadOutlinedIcon className='icon-outlined' /></div>}
          {branchName && userRole && userRole.canDownload(folderPath) && <div className='buttonFor download-btn' onClick={handleClickofdownloadFile}>
            <CloudDownloadOutlinedIcon className='icon-outlined' />
            <CloudDownloadIcon className='icon-filled' />
          </div>
          }
        </div>
      </div>
      {userRole.getCurrentUser() &&
        <div className="container">
          <div className="leftContainer" id="leftContainer">
            {dataFrobranches &&
              dataFrobranches.map((file, index) => (
                (file.name !== 'GUI/PROD' && file.name !== 'GUI/DEV' && file.name !== 'main' && file.name !== 'ReactApp') && <div key={file.name + "branch"} id={file.name + "branch"} className={`file ${memoizedActiveFileContent === file.name ? 'active' : ''}`}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleFileClick(file.name, file.objectId);
                  }}>

                  <span onClick={(event) => {
                    event.stopPropagation(); // Prevent event from bubbling up
                    minimizeBranch(file.name, file.objectId);
                  }} id={`plusMark` + file.name} className='arrowMarkClick'>
                    <KeyboardArrowRightIcon sx={{
                      fontSize: '3vh',
                      color: 'rgba(0, 0, 0, 0.70)'  // This sets the color to a lighter shade of black (default Material-UI text secondary color)
                    }} /></span>
                  <span className='folderLogoAndName'>
                    {/* <img className='folderLogo' src={icon} alt="Folder Icon" /> */}
                    <span className='folderLogo'><FolderIcon style={{ fontSize: 'inherit', color: 'inherit' }} /></span>
                    <span className='folderNameLeftSide'>{file.name}</span>
                  </span>

                </div>
              ))}
          </div>
          <div className="drag-pointer" id="dragPointer"></div>
          <div id="mainContainer" className="mainContainer">
            <div className={`fileContent${window.view}`} id={`fileContent${window.view}`}>
              {window.view === "List" && (
                <div className="folderContainerList">
                  <div className={`folderLogoMiddleListHeading`}></div>
                  <div className={`classForListTimeStamp${window.view}`}>
                    <div className={`folderNameListHeading`}><span className='marginLeftHeading'>Name</span></div>
                    <div className='dateAndTimeHeading'><span className='marginLeftHeading'>Date Modified</span></div>
                    <div className='typeCssHeading'><span className='marginLeftHeading'>Type</span></div>
                  </div>
                </div>
              )}
              <div id='output'></div>
              {userRole.getCurrentUser() && nestedData && nestedData.map((item, index) => {
                let dataforList = item.path.split('/');
                dataforList = dataforList.pop();
                const parts = item.path.split('/');
                return (
                  ((item.path.includes('BusinessRelease') && !item.path.includes('VSI-CI')) || item.path.includes('Submission') || (item.path.includes('Prerelease') && !item.path.includes('VSI-CI')) || (item.path.includes('Validation') && !item.path.includes('VSI-CI'))) ?
                    userRole.foldersForModuleOwner(item) :
                    !item.path.includes('.yml') && !item.path.includes(".sh")
                ) && (

                    <div
                      key={item.path.replace('/', '') + 'rightSide'}
                      className={`folderContainer${window.view}`}
                      onDoubleClick={(event) => {
                        if (item.isFolder) {
                          handleDoubleClick(item.path, item.BuildId, item.srcPath, event, item.lastChangedDate, branchName);
                        } else {
                          downloadSingleFile(item.path, event, item.BuildId, item.path.split('/').pop(), item.fileContent, instance, accounts);
                        }
                      }
                      }
                    >
                      {item.isFolder ? (
                        <div className={`folderLogoMiddle${window.view}`}><FolderIcon style={{ fontSize: 'inherit', color: 'inherit' }} /></div>
                      ) : (
                        item.path.split('/').pop().includes('.zip') ? <div className={`folderLogoMiddle${window.view}`}><FolderZipIcon style={{ fontSize: 'inherit', color: 'gray' }} /></div> : <div className={`folderLogoMiddle${window.view}`}><DescriptionIcon style={{ fontSize: 'inherit', color: 'gray' }} /></div>
                      )}

                      <div className={`classForListTimeStamp${window.view}`}>
                        <div
                          title={formatPathLabel(item.path.split('/').pop())}
                          className={`folderName${window.view}`}
                        >
                          {item.path.split('/').pop().length > (window.view === "List" ? 80 : 30)
                            ? `${formatPathLabel(item.path.split('/').pop().substring(0, (window.view === "List" ? 80 : 30)))}...`
                            : formatPathLabel(item.path.split('/').pop())
                          }
                        </div>

                        {window.view === "List" && (
                          <>
                            {<div className='dateAndTime'>
                              {item.lastChangedDate && <div className='date'>{item.lastChangedDate.date ? item.lastChangedDate.date : formatDate(item.lastChangedDate).date}</div>}
                              {item.lastChangedDate && <div className='time'>{item.lastChangedDate.time ? item.lastChangedDate.time : formatDate(item.lastChangedDate).time}</div>}
                              {item.lastChangedDate && <div className='ampm'>{item.lastChangedDate.ampm ? item.lastChangedDate.ampm : formatDate(item.lastChangedDate).ampm}</div>}
                            </div>}
                            {dataforList.includes('.') && <div className='typeCss'><span className='marginLeftHeading'>{dataforList.includes('.') ? checkFileFormat(dataforList.split('.').pop().toUpperCase()) : 'File Folder'}</span></div>}
                            {!dataforList.includes('.') && <div className='typeCss'><span className='marginLeftHeading'>File Folder</span></div>}
                          </>
                        )}
                      </div>
                    </div>
                  );
              })}
            </div>
            {userRole && showCreateFolderForm && (
              <CreateFolderForm
                folderPathRecived={folderPath}
                branchName={branchName}
                onCloseButton={() => {
                  closeErrorPopup(true);
                }}
                newBranch={'Folder'} />

            )}
            {userRole && showUploadFiles && (
              <UploadFiles
                branchName={branchName}
                folderPathRecived={folderPath}
                onCloseButton={() => {
                  closeErrorPopup();
                }} />
            )}
            <div className="list-view">
              <div className="view-toggle">
                <div id="list-view-btn" className='list-view-btn' onClick={(event) => handleListView(event)}>
                  <div className='list-view-btn-root '>
                    {window.view !== 'List' ? <ViewListOutlinedIcon className='list-viewButton-css' /> : <ViewListIcon className='list-viewButton-css' />}
                  </div>
                </div>
                <div id="thumbnail-view-btn" className='list-view-btn' onClick={(event) => handleThumbnailView(event)}>
                  <div className='thumbnail-View-btn'>
                    {window.view !== 'List' ? <ViewModuleIcon className='list-viewButton-css' /> : <ViewModuleOutlinedIcon className='list-viewButton-css' />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {showFileContent &&
        <FileViewerPopup open={true} onClose={() => setshowFileContent(false)} content={fileContentView} />
      }
      {isErrorPopupOpen && (
        <ErrorPopup message={formatPathLabel(message)} onClose={closeErrorPopup} />
      )}
      {showApproveButtonPup &&
        <ApproveBuildPopup
          filteredDataReceived={showApproveButtonPup.data}
          folderPath={folderPath}
          approvalId={showApproveButtonPup.approvalId}
          approvalStatus={showApproveButtonPup.approvalStatus}
          branchName={branchName}
          instance={instance}
          accounts={accounts}
          closePopupShown={() => { closeErrorPopup() }}
        />
      }
      <AzureBlobUploader />
    </AuthenticatedTemplate>
  );
};

export default RightContainer;
